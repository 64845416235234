<template>
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row :gutter="20">
        <el-col :span="3">
          <el-select v-model="form.tenantId" filterable placeholder="公司" clearable class="searchParam" size="medium">
            <el-option
                v-for="item in tenantIdArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-input v-model="form.styleName" placeholder="款式编号" size="medium"></el-input>
        </el-col>
        <el-col :span="3">
          <el-select v-model="form.brandId" filterable placeholder="款式品牌" clearable class="searchParam" size="medium">
            <el-option
                v-for="item in brandIdArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="form.styleLabelNames" filterable multiple placeholder="款式标签" clearable class="searchParam" size="medium">
            <el-option
                v-for="item in labelIdArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="search" size="medium">搜索</el-button>
        </el-col>
      </el-row>
    </fieldset>
    <div class="infinite-list-wrapper">
      <el-row :gutter="15" style="margin: 0 auto;" class="list" v-infinite-scroll="load" :infinite-scroll-disabled="disabled">
        <el-col :span="4" v-for="item in styleList" :key="item.id" style="margin: 1% 0 0 0" class="list-item">

            <div style="width: 14vw;height: 19vw">
              <el-image
                  style="width: 100%; height: 100%;cursor:zoom-in;" @click="openStyleImages(item.id)"
                  :src="'https://clothes-image-1304365928.cos.ap-shanghai.myqcloud.com/'+item.styleImage+'?imageMogr2/rquality/60'" fit="contain"/>
            </div>
            <div style="width: 14vw;">
              <el-row>
                <el-col :span="24" style="text-align: center">
                  <el-dropdown trigger="click" @command="handleCommand">
                  <span class="el-dropdown-link" style="cursor:pointer;font-size: 13px;font-weight: bold;">
                    操作 <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>
                  </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item icon="el-icon-edit" :command="['edit',item]">款式信息</el-dropdown-item>
                      <el-dropdown-item icon="el-icon-picture-outline" :command="['styleImage',item]">款式图片</el-dropdown-item>
                      <!--                    <el-dropdown-item icon="el-icon-price-tag" :command="['stylePrice',item]">款式价格</el-dropdown-item>-->
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-col>
              </el-row>
              <el-row >
                <el-col :span="8">
                  <p class="pTitle" style="text-align: left;font-weight: bold;font-size: 13px">{{item.brandName===''?'无品牌':item.brandName}}</p>
                </el-col>
                <el-col :span="8" >
                  <p class="pTitle" style="text-align: center;font-weight: bold;font-size: 13px">{{item.factoryName ===''?'无品牌名称':item.factoryName}}</p>
                </el-col>
                <el-col :span="8" >
                  <p class="pTitle" style="text-align: right;font-weight: bold;font-size: 13px">{{item.factoryNumber ===''?'无品牌编号':item.factoryNumber}}</p>
                </el-col>

              </el-row>
              <el-row>
                <el-col :span="12" style="text-align: center">
                  <p class="pTitle" style="text-align: left;font-weight: bold;font-size: 13px">{{item.tenantName ===''?'无公司':item.tenantName}}</p>
                </el-col>

                <el-col :span="12">
                  <p class="pTitle" style="text-align: right;font-weight: bold;font-size: 13px">{{item.styleAlias===''?'无别名':item.styleAlias}}</p>
                </el-col>
              </el-row>
            </div>

        </el-col>
      </el-row>
      <p  v-loading="loading" element-loading-text="加载中"></p>
      <p v-if="noMore">没有更多了...</p>
    </div>
    <styleEdit v-if="dialog.openEditState" :styleInfo="dialog.style" :state="dialog.openEditState" @close="close"/>
    <styleImage v-if="dialog.openImageState" :styleInfo="dialog.style" :state="dialog.openImageState" @close="close"/>
<!--    <stylePrice v-if="dialog.openStylePrice" :styleInfo="dialog.style" :state="dialog.openStylePrice" @close="close"/>-->
  </div>
</template>

<script>
import styleEdit from "@/pages/share/edit-list/style-edit-details";
import styleImage from "@/pages/share/edit-list/style-edit-image";
// import stylePrice from "@/pages/style/style-price";

export default {
  name: "style-list",
  data(){
    return{
      form:{
        styleName:"",
        tenantId:"",
        brandId:"",
        styleLabelNames:"",
        tenantCrop:localStorage.getItem("tenantCrop"),
      },
      tenantIdArray:[],
      brandIdArray:[],
      labelIdArray:[],
      dialog:{
        openEditState:false,
        openImageState:false,
        openStylePrice:false,
        style:{},
      },

      page:1,
      styleList:[],
      total:0,
      loading: true,
      disabled:true,
      noMore:false,
    }
  },
  components:{
    styleEdit,styleImage,
    // stylePrice
  },
  created() {
    this.queryStyleList();
    this.queryBrandIdsArr();
    this.queryLabelIdsArr();
    this.queryTenantIdArray();
  },
  methods:{
    search(){
      this.styleList=[];
      this.page=1;
      this.total=0;
      this.queryStyleList();
    },
    //查询客户列表
    queryStyleList:function (){
      this.$axios({
        method:"GET",
        url:"/style/queryAllStyle",
        params:{
          page:this.page,
          limit:30,
          styleLabelNames: this.form.styleLabelNames.toString(),
          brandId: this.form.brandId,
          tenantId:this.form.tenantId,
          styleName:this.form.styleName,
        }
      }).then(response=>{
        this.styleList.push(...response.data.data.list);
        console.log(this.styleList)
        this.total=response.data.data.total;
        this.loading = false
        this.disabled = false;
      })
    },
    handleCommand(command) {
      this.$axios({
        method:"GET",
        url:"/style/queryStyleDetails",
        params:{
          styleId:command[1].id
        }
      }).then(response=>{
        this.dialog.style=response.data.data;
        switch (command[0]){
          case 'edit':
            this.dialog.openEditState = true
            break;
          case 'styleImage':
            this.dialog.openImageState = true
            break;
          case 'stylePrice':
            this.dialog.openStylePrice = true
            break;
        }
      })

    },
    //查询品牌类型
    queryBrandIdsArr(){
      this.$selectShareUtils.queryBrandIds().then(response=>{
        this.brandIdArray=JSON.parse(response.data.data);
      })
    },
    //查询标签类型
    queryLabelIdsArr(){
      this.$selectShareUtils.queryLabelNames(this.$labelType.style).then(response=>{
        this.labelIdArray=JSON.parse(response.data.data);
      })
    },
    //查询公司ID
    queryTenantIdArray(){
      this.$selectUtils.queryTenantIds().then(response=>{
        this.tenantIdArray=JSON.parse(response.data.data);
        console.log(this.tenantIdArray)
      })
    },
    openStyleImages(styleId){
      this.$axios({
        method: "GET",
        url: "/styleImage/queryLinkListByStyleId",
        params: {
          styleId: styleId
        }
      }).then(response => {
        this.$viewerApi({
          images: response.data.data,
        })
      })
    },
    //更改每页长度
    handleSizeChange:function (val){
      this.table.limit=val;
      this.queryStyleList();
    },
    handleCurrentChange(val) {
      this.table.page =val
      this.queryStyleList()
    },
    //加载
    load() {
      this.disabled=true;
      if (this.styleList.length>=this.total){
        this.noMore=true;
        this.loading=false;
        return;
      }
      this.loading = true;
      this.noMore=false;
      this.page++;
      setTimeout(() => {
        this.queryStyleList();
      }, 1000)
    },
    close(){
      this.dialog.openEditState=false;
      this.dialog.openImageState=false;
      this.dialog.openStylePrice=false;
    }
  }
}
</script>

<style scoped>
p{
  margin: 5% 0;
  font-size: 23px;
  text-align: center;
}
.pTitle{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  margin: 0 0;
  font-size: 13px;
  font-weight: bold;
}
</style>