<template>
  <el-dialog
      title="款式图片"
      width="65%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-row :gutter="50" style="overflow-y: auto;height: 650px;">
      <el-col :span="5" v-for="(item,index) in styleImageList" :key="item.id" style="margin: 1% 0">
        <div>
          <el-image
              style="width: 250px; height: 300px;" @click="openImage(item.styleImage)"
              :src="'https://clothes-image-1304365928.cos.ap-shanghai.myqcloud.com/'+item.styleImage" fit="contain"/>
        </div>
        <el-row style="text-align: center;width: 250px;cursor:pointer;">
          <span class="el-dropdown-link">
              {{ item.imageTypeName }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
        </el-row>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  name: "style-image",
  data() {
    return {
      styleImageList: [],
      dialogState: this.state,
    }
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    styleInfo: {
      type: Object,
      required: true,
    }
  },
  created() {
    this.queryStyleImage();
  },
  methods: {
    //查询款式图片
    queryStyleImage() {
      this.$axios({
        method: "GET",
        url: "/styleImage/queryStyleImageList",
        params: {
          imageType:this.searchStyleType,
          styleId: this.styleInfo.id
        }
      }).then(response => {
        this.styleImageList = []
        this.styleImageList.push(...response.data.data)
      })
    },
    //放大图片
    openImage(link) {
      this.$viewerApi({
        images: ['https://clothes-image-1304365928.cos.ap-shanghai.myqcloud.com/' + link],
      })
    },
    close: function () {
      this.$emit("close", false);
    },
  }
}
</script>

<style scoped>

</style>