<template>
  <el-dialog
      title="款式信息"
      width="70%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-row>
      <el-col :span="18">
        <el-row>
          <el-col :span="24">
            <el-form label-width="130px">
              <el-row>
                <el-col :span="12">
                  <el-form-item label="款式类型" prop="styleType">
                    <el-input readonly v-model="styleInfo.typeName" class="searchParam"/>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="销售价格">
                    <el-input v-model="form.salePrice" type="number" class="searchParam"></el-input>
                  </el-form-item>
                </el-col>

              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="款式名称" prop="factoryName">
                    <el-input v-model="form.factoryName" class="searchParam"/>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="款式编号" prop="factoryName">
                    <el-input v-model="form.factoryNumber" class="searchParam"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="品牌">
                    <el-select v-model="form.brandName" filterable placeholder="选择品牌" clearable class="searchParam">
                      <el-option
                          v-for="item in brandNameArray"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="款式标签">
                    <el-select v-model="form.styleLabels" filterable multiple placeholder="选择标签" clearable class="searchParam">
                      <el-option
                          v-for="item in labelNameArray"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="22">
                  <el-form-item label="款式介绍" prop="styleInfo">
                    <el-input type="textarea" v-model="form.styleInfo"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="22">
                  <el-form-item label="适合身形" prop="styleFit">
                    <el-input type="textarea" v-model="form.styleFit"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="22">
                  <el-form-item label="不适合身形" prop="styleNoFit">
                    <el-input type="textarea" v-model="form.styleNoFit"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-col>
        </el-row>
        <el-row style="text-align: center">
          <el-col :span="6">
            <el-button type="primary" icon="el-icon-circle-plus" @click="addByStyleId()">添加到库</el-button>
          </el-col>
          <el-col :span="6">
            <el-button type="warning" icon="el-icon-upload2" @click="addByStyleInfo()">保存并添加到库</el-button>
          </el-col>
          <el-col :span="6">
            <el-button type="success" icon="el-icon-shopping-bag-1" @click="addStoreByStyleInfo()">保存并添加到商城</el-button>
          </el-col>
          <el-col :span="6">
            <el-button type="success" icon="el-icon-s-promotion" @click="drawerState=true">分享到用户</el-button>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="6">
        <p style="background-color: #d6e0f5;text-align: center;font-size: 20px;padding: 2% 0;font-weight: bold">店内参考</p>
        <br>
        <el-form label-width="130px">
          <el-row>
            <el-col>
              <el-form-item label="店内编号" prop="styleType">
                <el-input disabled v-model="styleInfo.styleName" style="width: 150px"/>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="店内名称" prop="styleType">
                <el-input disabled v-model="styleInfo.styleAlias" style="width: 150px"/>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="店内件数" prop="clothesCount">
                <el-input disabled v-model="styleInfo.clothesCount" style="width: 150px"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="出件次数">
                <el-input disabled v-model="count.outCount" type="number" style="width: 150px"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="试纱次数">
                <el-input disabled v-model="count.yarnCount" type="number" style="width: 150px"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>

    <el-drawer
        title="款式分享"
        :visible.sync="drawerState"
        direction="rtl"
        :append-to-body="true">
      <el-form ref="form" label-width="80px">
        <el-form-item label="目标公司 :">
          <el-select v-model="tenantId" filterable placeholder="公司" @change="changeTenant" clearable size="medium">
            <el-option
                v-for="item in tenantIdArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="shareStyle">立即分享</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </el-dialog>
</template>

<script>
export default {
  name: "style-edit-details.vue",
  data() {
    return {
      form:{
        styleType:this.styleInfo.styleType,
        salePrice:"",
        styleLabels:this.styleInfo.labelName.split(","),
        factoryName:this.styleInfo.factoryName,
        factoryNumber:this.styleInfo.factoryNumber,
        brandName:this.styleInfo.brandName,
        styleInfo:this.styleInfo.styleInfo,
        styleFit:this.styleInfo.styleFit,
        styleNoFit:this.styleInfo.styleNoFit,
      },
      brandNameArray:[],
      labelNameArray:[],
      dialogState: this.state,
      drawerState:false,
      tenantIdArray:[],
      tenantName:"",
      tenantId:"",
      checkTenant:{},
      count:{},
      tenantCrop:localStorage.getItem("tenantCrop"),
    }
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    styleInfo: {
      type: Object,
      required: true,
    }
  },
  watch:{
  },
  created() {
    console.log(this.styleInfo)
    this.queryTenantIdArray();
    this.queryBrandNames();
    this.queryLabelNames();
    this.queryCount();
  },
  methods: {
    addByStyleId(){
      this.$confirm('是否将该款式添加到款式库?</br> 该款式的 品牌 标签 款式等信息将同步到库', '添加到库', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(() => {
        this.$axios({
          method:"PUT",
          url:"/libraryStyle/addByStyleId",
          params:{
            styleId:this.styleInfo.id,
            tenantCrop:this.tenantCrop
          }
        }).then(response=>{
          if(response.data.code===200){
            this.$message.success('已添加到品牌库');
            return;
          }
          this.$message.error(response.data.msg);
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消添加'
        });
      });
    },
    addByStyleInfo(){
      this.$confirm('是否将修改后的款式信息添加到款式库?</br> 该款式的 品牌 标签 款式等信息将同步到库', '保存并添加到库', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(() => {
        this.$axios({
          method:"PUT",
          url:"/libraryStyle/addByStyleInfo",
          params:{
            styleType:this.form.styleType,
            salePrice:this.form.salePrice,
            styleLabelNames:this.form.styleLabels.toString(),
            styleImage:this.styleInfo.styleImage,
            factoryName:this.form.factoryName,
            factoryNumber:this.form.factoryNumber,
            brandName:this.form.brandName,
            styleInfo:this.form.styleInfo,
            styleFit:this.form.styleFit,
            styleNoFit:this.form.styleNoFit,
            id:this.styleInfo.id,
            tenantCrop:this.tenantCrop
          }
        }).then(response=>{
          if(response.data.code===200){
            this.$message.success('已添加到品牌库');
            return;
          }
          this.$message.error(response.data.msg);
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消添加'
        });
      });
    },
    //添加到商城
    addStoreByStyleInfo(){
      console.log(this.form)
      this.$confirm('是否将修改后的款式信息添加到款式商城?</br> 该款式的 品牌 标签 款式等信息将同步到库', '保存并添加商城', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(() => {
        this.$axios({
          method:"PUT",
          url:"/storeStyle/addByStyleInfo",
          params:{
            styleType:this.form.styleType,
            salePrice:this.form.salePrice,
            styleLabelNames:this.form.styleLabels.toString(),
            styleImage:this.styleInfo.styleImage,
            factoryName:this.form.factoryName,
            factoryNumber:this.form.factoryNumber,
            brandName:this.form.brandName,
            brandId:this.styleInfo.brandId,
            publishStatus:1,
            styleInfo:this.form.styleInfo,
            styleFit:this.form.styleFit,
            styleNoFit:this.form.styleNoFit,
            id:this.styleInfo.id,
            tenantCrop:this.tenantCrop
          }
        }).then(response=>{
          if(response.data.code===200){
            this.$message.success('已添加到款式商城');
            return;
          }
          this.$message.error(response.data.msg);
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消添加'
        });
      });
    },
    queryTenantIdArray(){
      this.$selectUtils.queryTenantIds().then(response=>{
        this.tenantIdArray=JSON.parse(response.data.data);
      })
    },
    changeTenant(value){
      if (value===''){
        this.tenantName="";
        this.tenantId="";
        return;
      }
      let checkSelect=this.tenantIdArray.filter(k=>{ return k.value===value});
      if (checkSelect.length===0||checkSelect.length>1){
        this.notifyError();
        return;
      }
      this.checkTenant=checkSelect[0];
      this.tenantId=value;
      this.tenantName=this.checkTenant.name;
    },
    shareStyle(){
      if (Object.keys(this.checkTenant).length===0
      ||this.tenantName!==this.checkTenant.name
      ||this.tenantId!==this.checkTenant.value){
        this.notifyError();
        return;
      }
      this.$confirm(`是否将该款式分享给[${this.tenantName}]?`, '款式分享', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios({
          method:"PUT",
          url:"/style/shareStyleToTenantByStyleId",
          params:{
            styleId:this.styleInfo.id,
            tenantId:this.tenantId
          }
        }).then(response=>{
          if(response.data.code===200){
            this.$message.success(`已将款式分享给${this.tenantName}`);
            return;
          }
          this.$message.error(response.data.msg);
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消分享'
        });
      });
    },
    notifyError(){
      this.$notify.error({
        title: '错误',
        message: '公司选择出现错误,请刷新再试!'
      });
    },
    //查询品牌类型
    queryBrandNames(){
      this.$selectShareUtils.queryBrandNames(2).then(response=>{
        this.brandNameArray=JSON.parse(response.data.data);
      })
    },
    //查询标签类型
    queryLabelNames(){
      this.$selectShareUtils.queryLabelNames(2).then(response=>{
        this.labelNameArray=JSON.parse(response.data.data);
        // this.form.styleLabels=this.styleInfo.labelName.split(",");
      })
    },
    queryCount() {
      this.$axios({
        method: "get",
        url: "/style/queryYarnCountAndOutCountByStyleId",
        params: {
          styleId: this.styleInfo.id,
          tenantCrop: this.styleInfo.tenantCrop,
        }
      }).then(response => {
        this.count = response.data.data
      })
    },
    close: function () {
      this.$emit("close", false);
    }
  }
}
</script>

<style scoped>
.searchParam{
  width: 220px;
}
.el-drawer__body >>> .el-form{
  padding: 0 5%;
}
</style>